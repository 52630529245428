<template>
    <div id="mastercard-landing-page">
        <BannerPartner />

        <div class="container p-4 p-md-5">
            <p class="m-0 text-center mb-4 h4 text-dark">Fique do lado seguro:</p>

            <ProductsCatalog :products="products" :add-product="addProduct" :open-cart="openCart" />

            <Platforms htmlClass="mt-5 text-center" />

            <template v-if="partner !== Partners.MASTERCARD">
                <Brands />
            </template>

            <p class="m-0 text-center small text-dark">* O preço mostrado corresponde ao primeiro ano. Consulte abaixo os detalhes da oferta.</p>
        </div>

        <CheckList />

        <div class="mt-3 container">
            <Carousel />
        </div>

        <AntivirusProtectionFeature />

        <div class="mt-4">
            <CustomerTrust />
        </div>

        <WhyMcAfee />

        <DeviceProtection />

        <Services />

        <div class="container mt-4 p-md-5 w-100 mb-5 d-flex flex-column align-items-center">
            <p class="h3 text-dark fw-normal text-center mb-4">Ative sua proteção <b class="fw-bold text-danger">hoje</b></p>

            <p class="text-dark text-center">Ao ativar sua assinatura adicionaremos os dias restantes da sua avaliação a ela, assim você não perderá nenhum momento da proteção grátis.</p>

            <ProductsCatalog :products="products" :add-product="addProduct" :open-cart="openCart" />
        </div>

        <Faq />
    </div>
</template>

<script setup>
    import { computed, onMounted, ref } from "vue";
    import { useStore } from "vuex";

    import Faq from "@/modules/landingPages/mcafee/components/Faq";
    import Brands from "@/modules/landingPages/mcafee/components/Brands";
    import Carousel from "@/components/Carousel/Carousel.vue";
    import Services from "@/modules/landingPages/mcafee/components/Services";
    import WhyMcAfee from "@/components/WhyMcAfee";
    import Platforms from "@/modules/landingPages/mcafee/components/Platforms";
    import CheckList from "@/modules/landingPages/mcafee/components/CheckList";
    import BannerPartner from "@/components/BannerPartner";
    import CustomerTrust from "@/modules/landingPages/mcafee/components/CustomerTrust";
    import ProductsCatalog from "@/components/Sale/ProductsCatalog";
    import DeviceProtection from "@/modules/landingPages/mcafee/components/DeviceProtection";
    import AntivirusProtectionFeature from "@/modules/landingPages/mcafee/components/AntivirusProtectionFeature";

    import Configuration from "@/services/configuration";
    import productTypesOptionsConstants from "@/constants/sale/productTypesOptions.constants";
    import Partners from "@/constants/partner/partner.constants";

    // * setup
    const store = useStore();

    // * props
    const products = computed(() => store.getters['saleFlow/product/getProducts']);
    const partner = ref('');

    // * methods
    function addProduct({ product, promotion }) {
        store.dispatch('cart/addProductCart', { product, promotion });
    }

    function openCart() {
        store.dispatch('cart/openCart');
    }

    async function getProducts() {
        const productType = partner.value === Partners.MASTERCARD ? (
            productTypesOptionsConstants.MCAFEE_DIGITAL_PROTECTION_MASTERCARD_PARTNERSHIP
        ) : (
            productTypesOptionsConstants.MCAFEE_DIGITAL_PROTECTION
        );

        await store.dispatch('saleFlow/product/setAvailableProductType', [
            Configuration.productTypeOption[productType]
        ]);

        store.dispatch('saleFlow/getAllProductsCatalog');
    }

    onMounted(async () => {
        partner.value = store.getters.getPartner;
        store.dispatch('address/setNoClaroFields');
        await getProducts();
    });
</script>

<style lang="scss">
    #header > div {
        padding: 1rem;
    }
</style>
