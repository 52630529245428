<template>
    <img :src="banner" :alt="bannerAltAttribute" class="w-100">
</template>

<script setup>
    import { useStore } from "vuex";
    import { onMounted, ref } from "vue";

    import defaultBanner from '@/assets/img/banner-rigstar.png';

    // * setup
    const store = useStore();

    // * props
    const partner = ref('');
    const bannerAltAttribute = ref('');
    const banner = ref();

    onMounted(() => {
        partner.value = store.getters.getPartner;

        const config = store.getters.getPartnerConfiguration;

        if ('banner' in config && 'image' in config.banner) {
            config.banner.image().then(module => banner.value = module.default);

            if ('alt' in config.banner) {
                bannerAltAttribute.value = config.banner.alt;
            }

            return;
        }

        banner.value = defaultBanner;
    });
</script>
